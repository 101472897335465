.blinking-circles {
  // float: right;
  position: absolute;
  bottom: 0;
  margin: 0 0 0 5px;
  // .button-group {
  //   float: right;
  //   top:52px;
  //   position: relative;
  // }

  button {
    margin: 0 0 8px 3px;
  }

  .circles {
    position: fixed;
    bottom: 0px;
  }
}