.panel-top {
    width: 100%;
}

.height-100 {
  overflow: hidden;
}

.error-output {
  // width: 600px;
}

.overflow-auto {
  // height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  // display: inline-block;
}

.overflow-auto.section-border {
  display: inline-block;
  transition: opacity 0.5s  ease-in-out, transform 0.5s ease-in-out;;
}

.animated-div {
  // width: 100px;
  // height: 100px;
  // background-color
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.animate {
  opacity: 1;
}

.left-background-logo {
  position: absolute;
}

.right-side-right-angle {
  position: absolute;
}

/* DiagonalLines.css */

// .diagonal-lines-container {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100px;
//     overflow: hidden;
//   }
  
//   .diagonal-line-up,
//   .diagonal-line-down {
//     position: relative;
//     height: 100%;
//   }
  
//   .diagonal-line-up::before,
//   .diagonal-line-down::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
  
//   .diagonal-line-up::before {
//     background: linear-gradient(to bottom right, #0000ff 49.5%, #000 50.5%);
//     clip-path: polygon(0 0, 100% 0, 0 100%);
//   }
  
//   .diagonal-line-down::before {
//     background: linear-gradient(to top right, #0000ff 49.5%, #000 50.5%);
//     clip-path: polygon(0 0, 100% 100%, 100% 0);
//   }
  
//   .center-line {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 200px; /* Adjust the width as needed */
//     height: 100%;
//     background: linear-gradient(to bottom, #000, #0000ff); /* Gradient for the center line */
//   }
  


.diagonal-lines-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
  }
  
  .diagonal-line {
    flex-grow: 1;
    height: 100%;
  }
  
  .diagonal-line-up::before {
    // background: linear-gradient(to bottom right, transparent 49.5%, #0000ff 49.5%, #0000ff 50.5%, transparent 50.5%);
    background: linear-gradient(to bottom right, #0000ff 49.5%, #000 50.5%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
  
  .diagonal-line-down::before {
    // background: linear-gradient(to top right, transparent 49.5%, #000 49.5%, #000 50.5%, transparent 50.5%);
    background: linear-gradient(to top right, #0000ff 49.5%, #000 50.5%);
    clip-path: polygon(0 100%, 100% 100%, 0 0);
  }
  
  .center-line {
    width: 700px;
    height: 100%;
    background: #000; /* Color for the center line */
  }


// .diagonal-lines-container {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100px;
//     overflow: hidden;
//   }
  
//   .diagonal-line-up,
//   .diagonal-line-down {
//     position: relative;
//     height: 100%;
//   }
  
//   .diagonal-line-up::before,
//   .diagonal-line-down::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
  
//   .diagonal-line-up::before {
//     background: linear-gradient(to bottom right, #0000ff 49.5%, #000 50.5%);
//     clip-path: polygon(0 0, 100% 0, 100% 100%);
//   }
  
//   .diagonal-line-down::before {
//     background: linear-gradient(to top right, #0000ff 49.5%, #000 50.5%);
//     clip-path: polygon(0 100%, 100% 100%, 0 0);
//   }
  
//   .center-line {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 200px; /* Adjust the width as needed */
//     height: 100%;
//     background: linear-gradient(to bottom, #000, #0000ff); /* Gradient for the center line */
//   }
  



// .diagonal-lines-container {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100px;
//     overflow: hidden;
//   }
  
//   .diagonal-line-up,
//   .diagonal-line-down {
//     position: relative;
//     height: 100%;
//   }
  
//   .diagonal-line-up::before,
//   .diagonal-line-down::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }
  
//   .diagonal-line-up::before {
//     background: linear-gradient(to bottom right, #0000ff 49.5%, #000 50.5%);
//   }
  
//   .diagonal-line-down::before {
//     background: linear-gradient(to top right, #0000ff 49.5%, #000 50.5%);
//   }
  
//   .center-line {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 200px; /* Adjust the width as needed */
//     height: 100%;
//     background: linear-gradient(to bottom, #000, #0000ff); /* Gradient for the center line */
//   }
  
  




.border {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #f1c40f;
  border-bottom: 4px solid black;
}

.border::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid black;
}

.border::after {
  content: '';
  position: absolute;
  top: -4px;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid black;
}

