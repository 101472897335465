// .counters > span > span {
//     display: block !important;;
// }

.counters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust gap as needed */
  }
  
  .button-container {
    flex: 1 1 calc(25% - 10px); /* Adjust percentage to control how many buttons per row */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .round {
    // width: 100%;
    height: 50px; /* Adjust height as needed */
    max-width: 150px; /* Set max-width for consistency */
    border-radius: 50%; /* Makes button round */
    text-align: center;
  }
  
  .count-outputs {
    margin-top: 5px; /* Adjust margin as needed */
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .button-container {
      flex: 1 1 calc(33.33% - 10px); /* 3 buttons per row */
    }
  }
  
  @media (max-width: 768px) {
    .button-container {
      flex: 1 1 calc(50% - 10px); /* 2 buttons per row */
    }
  }
  
  @media (max-width: 480px) {
    .button-container {
      flex: 1 1 100%; /* 1 button per row */
    }
  }
  