.piano-container {
  display: flex;

  button {
    flex: 1;
    height: 100px;
    background-color: white;
    border: 1px solid #000;
  }

  button:nth-child(odd) {
    background-color: #000;
    color: white;
  }
}