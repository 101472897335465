// // .flex-container {
// //     display: flex;
// //     width: 100%;
// // }

// // .flex-child {
// //     flex: 3;
// //     // border: 2px solid yellow;
// //     margin-right: 10px;
// // }

// // .flex-child-2 {
// //     flex: 4;
// //     // border: 2px solid yellow;
// //     // margin-right: 10px;
// // }

// // .flex-child-3 {
// //     flex: 2;
// //     // border: 2px solid yellow;
// // }

// // .flex-child:first-child {
// //     margin-right: 10px;
// // }

// .header { grid-area: header; }
// .menu { grid-area: menu; 
//     // width: 515px;
//     // width: 20%;
// }
// .main { grid-area: main; }
// .right { grid-area: right; 
//     // width: 358px;
// }
// .footer { grid-area: footer; }

// .grid-container {
//   display: grid;
//   grid-template-areas:
//     'header header header'
//     'menu main right'
//     'footer footer footer';
//   gap: 10px;
// //   grid-template-columns: repeat(6, minmax(0, 1fr)); /* Adjust the number of columns as needed */
// //   grid-template-rows: repeat(3, minmax(0, 1fr)); /* Adjust the number of rows as needed */
// //   background-color: #2196F3;
// //   padding: 10px;
// }

// .grid-container > div {
// //   background-color: rgba(255, 255, 255, 0.8);
// //   text-align: center;
// //   padding: 20px 0;
// //   font-size: 30px;
// }



// // button {
// //     color: #fff;
// //     padding: 25px;
// //     margin: 10px 10px 10px 0;
// //     /* background-color: #097479; */
// // }


.three-column-layout {
    display: flex;
    width: 100%;
  }
  
  .column {
    // border: 1px solid #000;
    box-sizing: border-box;
    overflow: auto; 
  }
  
  // .column-30 {
  //   flex: 0 0 25%;
  //   // min-width: 25%;
  //   // max-width: 25%;
  // }
  
  // .column-40 {
  //   flex: 0 0 50%;
  //   // min-width: 50%;
  //   // max-width: 50%;
  // }




  
  // .container {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 10px;
  // }
  
  // .grid-container {
  //   display: flex;
  //   flex-direction: column;
  // }
  
  // .header, .footer {
  //   width: 100%;
  // }
  
  // .three-column-layout {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }
  
  // .column {
  //   box-sizing: border-box;
  //   overflow: auto;
  //   padding: 10px;
  // }
  
  // .column-30 {
  //   flex: 0 0 25%;
  //   min-width: 25%;
  //   max-width: 25%;
  // }
  
  // .column-40 {
  //   flex: 0 0 50%;
  //   min-width: 50%;
  //   max-width: 50%;
  // }
  
  // .section-border {
  //   border: 1px solid #000;
  // }
  
  // @media (max-width: 768px) {
  //   .column-30, .column-40 {
  //     flex: 0 0 100%;
  //     min-width: 100%;
  //     max-width: 100%;
  //   }
  // }
  
  