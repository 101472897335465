.todos {
  .todo-text {
    overflow: auto;
    width: 180px;
    height: 58px;
    display: block;
  }
}

.draggable-row-class {
  // Default style for rows
  // background-color: white;
  transition: background-color 0.3s ease;

  // &.transparent-row {
  //   background-color: rgba(255, 255, 255, 0.5); // Adjust opacity as needed
  // }
}


// ul {
//   list-style: none;
//   padding: 0;
// }

// li {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   // padding: 10px 10px 10px 10px;
//   padding: 0 !important;
//   margin: 0 !important;
//   // border-bottom: 1px solid #ccc;

//   &:last-child {
//     border-bottom: none;
//   }

//   span {
//     flex: 1;
//     cursor: pointer;

//     &.completed {
//       text-decoration: line-through;
//     }
//   }

//   button {
//     background-color: red;
//     color: #fff;
//     border: none;
//     border-radius: 5px;
//     padding: 5px 10px;
//     cursor: pointer;
//     height: 53px;
//     width: 53px;
//   }

//   .todo-text {
//     font-size: 30px;
//   }
// }

// .todo-form {
//   // display: grid;
//   // grid-template-columns: 1fr 183px 1fr 75px 1fr; // Create a single row grid
//   // align-items: center; // Vertically center the items
//   // gap: 5px; // Adjust the gap as needed
//   // // padding: 10px;

//   label,
//   input,
//   button {
//     // padding: 10px;
//     padding: 10px 0 10px 0 !important;
//     padding: 0 !important;
//     font-size: 30px;
//   }

//   // #todoTitle {
//   //   width: 215px;
//   // }

//   button:hover {
//     background-color: #0056b3;
//   }

//   .column-priority {
//     width: 50px;
//   }
// }

// .column-priority {
//     width: 50px;
// }