// InvoiceForm.scss
.invoice-form {
  width: 100%;
  // max-width: 600px;
  // margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  // background-color: #f7f7f7;
  box-sizing: border-box;
}

.form-section {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input,
.form-textarea {
  width: 100%;
  // padding: 10px;
  height: 48px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

// .add-item-button,
// .submit-button {
//   background-color: blue;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
// }

.item-container {
  display: flex;
  align-items: center;

  .form-input {
    margin-right: 10px;
  }

  .quantity-input {
    margin-right: 10px;
  }

  .quantity-input,
  .cost-input {
    width: 30%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
}

.address-section {
  display: flex;
  justify-content: space-between;
}

.address-section>div {
  flex: 1;
  // margin-right: 20px;
  /* Add margin between the sections */
}

.address-section>div:first-child {
  margin-right: 10px; /* Adjust the margin as needed */
}

.form-label {
  display: block;
  margin-bottom: 5px;
}