.sound-board {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  .sound-button {
    width: 100%;
    height: 100px; /* Adjust the height as needed */
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  
  .sound-button:hover {
    background-color: #2980b9;
  }
  