// LoginForm.scss

.login-form {
  max-width: 240px;
  margin: 40px auto;
  padding: 20px;
  // background-color: #f4f4f4;
  // border: 1px solid red;
  // border-radius: 5px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  button {
    // background-color: #4caf50;
    // color: #fff;
    // padding: 10px;
    // border: none;
    // border-radius: 3px;
    // cursor: pointer;
    // font-size: 16px;
  }
}

// Additional styles for the second step (password input)
.login-form.password-step {
  label {
    margin-top: 15px; // Add space between username and password labels
  }
}
