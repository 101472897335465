/* General styles for the invoice container */
.invoice-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  border: 1px solid #ddd;
  position: relative;
}

/* Styles for the header section */
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Styles for the address section */
.invoice-address {}

.address-line {
  margin: 5px 0;
}

/* Styles for the details section */
.invoice-details {
  width: 45%;
  text-align: right;
}

/* Styles for the items section */
.invoice-items table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: black;
  color: white;
  font-weight: bold;
}

.invoice-items th {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  background-color: black;
}

.invoice-items td {
  border: 0px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Styles for the summary section */
.invoice-summary-container {
  position: relative;
  margin-top: 20px;
}

.invoice-summary {
  position: absolute;
  top: 0;
  right: 0;
}

.summary-item {
  margin-bottom: 5px;
}

.total {
  color: green;
}

.balance-due {
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 8px;
  margin-top: 20px;
  background-color: #ddd;
  color: black;
}

/* Styles for the notes section */
.invoice-notes {
  margin-top: 20px;
  clear: both;
}

/* Styles for the terms section */
.invoice-terms {
  margin-top: 10px;
  clear: both;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .invoice-address,
  .invoice-details,
  .invoice-items,
  .invoice-summary,
  .invoice-notes,
  .invoice-terms {
    width: 100%;
  }

  .invoice-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.lower-invoice {
  margin-top: 75px;
  clear: both;
}

.invoice-text {
  font-size: 40px;
  margin: 0px;
}

.invoice-number {
  margin-top: 0px;
  margin-bottom: 45px;
}

.right-align {
  text-align: right !important;
}